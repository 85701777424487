

.box {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0px;
    -webkit-transition: .5s;
    transition: .5s;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

.box-header {
    color: #191d33;
    display: block;
    padding: 1rem;
    position: relative;
    border-bottom: 1px solid #f0f3f6;
    
}

.box-header .box-title {
    display: inline-block;
    margin: 0;
    font-weight: 400;
}
.box-title {
    z-index: auto;
}


.scr-bar::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  .scr-bar::-webkit-scrollbar-track {
    background: #ffffff; 
    
  }
   
  /* Handle */
  .scr-bar::-webkit-scrollbar-thumb {
    background:  #0762a3; 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  .scr-bar::-webkit-scrollbar-thumb:hover {
    background:  #035086; 
  }