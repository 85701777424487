.app {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a:hover {
  color: #0762a3;
}

.btn:focus {
  box-shadow: none;
}

.btn-primary {
  background-color: #0762a3 !important;
}

.box {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

/* effect */

.hover_effect {
  transition: 0.3s ease-in-out;
}

.hover_effect:hover {
  box-shadow: 0px 20px 30px rgb(170, 170, 170);
  transform: translateY(-5px) scale(1.02);
}

.password-icon {
  cursor: pointer;
  position: relative;
  left: calc(100% - 30px);
  bottom: 47px;
  color: #0762a3;
}

.banner-icon {
  border-radius: 50%;
  display: flex;
  padding-top: 7px;
  justify-content: center;
  align-items: center;
  width: 70px;
  margin-bottom: 10px;
  height: 70px;
  background-color: #ffffff;
  color: #0762a3;
  transition: 0.5s all;
}
.banner-icon:hover {
  color: #f56816;
}

/* forms  */

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control,
.form-select {
  box-shadow: none;
  background-color: #f0f8ff;
  border-color: #f6f9fb;
  height: auto;
  padding: 0.45rem 0.9rem;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #80c0ff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 128 255 / 25%);
}

.form-control:focus {
  border-color: #f0f3f6;
  background-color: #f0f3f6;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

tbody + thead {
  display: none;
}

.table_height {
  height: 300px;
  overflow-y: auto;
}

.scr-bar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scr-bar::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.scr-bar::-webkit-scrollbar-thumb {
  background: #0762a3;
  border-radius: 20px;
}

/* Handle on hover */
.scr-bar::-webkit-scrollbar-thumb:hover {
  background: #035086;
}
.modelcontent2 {
  width: 400px;
}

button.react-responsive-modal-closeButton {
  display: none !important;
}

.btn-primary {
  background-color: #2952a1 !important;
  border-color: #2952a1 !important;
  color: white !important;
}
.forgotpswrdtext {
  margin-bottom: 2%;
  float: right;
  cursor: pointer;
  color: #abafb2 !important;
}
.loginimg {
  margin-left: -12px;
}
.loginbtn {
  width: 100%;
}

.login-icon-change-pass {
  float: right;
  /* bottom: 0px; */
  position: absolute;
  right: 9px;
  top: 8px;
}

.col-lg-6.bg-white.rounded-3.shadow-lg.row.m-0.p-0 {
  height: 400px;
}

.copyrighttext {
  margin-top: 2%;
}

.copyrightlink {
  color: #2952a1 !important;
}

@media screen and (min-device-width: 300px) and (max-device-width: 768px) {
  .loginimg {
    display: none;
  }
}

.copyrightfooter {
  color: white !important;
  float: right;
}
.Processform {
  margin-top: 2%;
}

button.btn.btn-primary.rounded.btn-lg.btn-block {
  margin: 10px;
  float: right;
}

.mandatory {
  color: red;
}
.mandatorytext {
  color: gray;
}

ul.breadcrumb {
  padding-top: 10px;
  padding-right: 20px;

  list-style: none;
  float: right;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: gray;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #292d31;
  text-decoration: none;
}
.ProcessFormSpace {
  margin-left: 1%;
  margin-right: 1%;
}
.ProcessFormSpace2 {
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
}

.page-item.active .page-link {
  background-color: #2952a1 !important;
  border-color: #2952a1 !important;
  color: white !important;
}
/* .text-primary-btn {
  color: #2952a1 !important;
} */

.btn-light {
  color: #000;
  background-color: #d7ecfc !important;
  border-color: #d7ecfc !important;
}
a {
  color: #2952a1;
}
a.btn.btn-light.text-primary-btn.addProcess {
  margin-right: 10px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white !important;
  background-color: #2952a1 !important;
}

a.footercopyright {
  color: white !important;
}

.btn {
  cursor: pointer !important;
}

.apexcharts-toolbar {
  margin-top: -10px !important;
}
