.sidebar_link {
  margin: 25px 0px;

  cursor: pointer;
  transition: 1s all;
}

.sidebar_link:hover {
  color: #0762a3;
}

.sidebar_link:active {
  color: #0762a3;
}

.sidebar_parent {
  transition: all 600ms ease;
  height: 100vh;
  position: fixed;
  background-color: #fff;
}

.sidebar_parent--shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px inset -2px 0px 0px #fff;
}
