
.schedule-box-scr-bar::-webkit-scrollbar {
    height: 30px;
  }
  
  /* Track */
  .schedule-box-scr-bar::-webkit-scrollbar-track {
    background: #ffffff; 
  }
   
  /* Handle */
  .schedule-box-scr-bar::-webkit-scrollbar-thumb {
    background:  #0762a3; 
    border-radius: 20px;
    border: 10px solid white;
  }
  
  /* Handle on hover */
  .schedule-box-scr-bar::-webkit-scrollbar-thumb:hover {
    background:  #035086; 
  }