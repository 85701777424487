.btn-outline-primary:hover {
  background-color: #0762a3;
  color: white;
}

.pulse-wave::after {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  left: 0px;
  top: -5px;
  opacity: 0;
  border: 3px solid #0762a3;
  border-radius: 40px;
  animation: pulsate 3.5s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.header_icon {
  font-size: 20px;
  color: #0483de;
  padding: 5px 10px;
  background-color: #d7ecfc;
  border-radius: 10px;
  transition: 0.5s all;
}

.header_icon:hover {
  background-color: #0762a3;
  color: #fff;
}

.dropdown-menu {
  position: absolute;
  width: 300px;
  max-width: 250px;
  padding: 0;
  margin-top: 45px !important;
  margin-left: -50px !important;
  /* transform: translate(10px, 35px) !important; */
  top: 100%;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.header {
  background-color: #ffffff;
  border-bottom: 1px solid #f0f3f6;
  color: #191d33;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  padding: 0;
}
